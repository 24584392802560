import React, { useState, useEffect } from 'react';

export default function Greetings() {
    const [data, setData] = useState('');

    useEffect(() => {
        (async function () {
            const { text } = await (await fetch("http://localhost:7071/api/Message")).json();
            setData(text);
        })();
    });

    return (<div>{data}</div>);
}

