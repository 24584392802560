import React from 'react';
import './App.css';
import useDocumentTitle from './Services/useDocumentTitle';
import Logo from './Components/logo';
import Greetings from './Components/file';

function App() {

    const env = process.env.NODE_ENV === "development" ? " - Utveckling" : "";
    useDocumentTitle("Virkverket" + env);
    return (
        <>
            <div className="App">
                <Logo />
            </div>
            <div>
                <Greetings />
            </div>
      
        </>);
}

export default App;
